import {defaultFieldData, SchemaType} from './StackLayoutDefinition';
import {ModuleNode, WithHtmlId} from '@backstage-components/base';
import {VFC} from 'react';
import styled from '@emotion/styled';
import {computeLayout} from './StackLayout';

export type StackedProps = SchemaType;

type StyledContainerProps = Pick<
  StackedProps,
  'sizing' | 'background' | 'styleAttr'
>;

const Styled = styled.div<StyledContainerProps>`
  ${({sizing, background, styleAttr}) =>
    `
    ${
      background?.backgroundImage &&
      `background-image: url(${background?.backgroundImage})`
    };
    ${
      background?.backgroundColor &&
      `background-color: ${background?.backgroundColor}`
    };
    ${`background-repeat: ${
      background?.backgroundRepeat ??
      defaultFieldData.background?.backgroundRepeat
    }`};
    ${`background-position: ${
      background?.backgroundPosition ??
      defaultFieldData.background?.backgroundPosition
    }`};
    ${`background-size: ${
      background?.backgroundSize ?? defaultFieldData.background?.backgroundSize
    }`};
    ${sizing?.minHeight && `min-height: ${sizing?.minHeight}`};
    ${sizing?.maxHeight && `max-height: ${sizing?.maxHeight}`};
    ${sizing?.height && `height: ${sizing?.height}`};
    ${styleAttr}`};
`;

export const StackLayoutPreview: VFC<
  ModuleNode<'Stacked', StackedProps> & WithHtmlId
> = ({module: {props, id}, slotComponent: Slot}) => {
  const {preset, align, orientation} = props.layout || {
    orientation: 'vertical',
  };
  const layoutStyle = computeLayout(preset, align, orientation);
  return (
    <Styled
      id={id}
      css={{...layoutStyle}}
      background={props.background}
      sizing={props.sizing}
      styleAttr={props.styleAttr}
    >
      <Slot
        slotName="items"
        orientation={props.layout?.orientation}
        autoLayout={props.autoLayout}
      />
    </Styled>
  );
};
