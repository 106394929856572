import {
  ContentModule,
  MotionOptions,
  ModuleProperties,
  useMotionOptions,
  applyTween,
  useShowInstructions,
  WithHtmlId,
} from '@backstage-components/base';
import {FC, VFC, useState} from 'react';
import {css, SerializedStyles} from '@emotion/react';
import {
  SchemaType,
  instructions,
  reactName as namespace,
} from './ButtonDefinition';
import {
  Button as ButtonElm,
  ButtonProps,
  keyframes,
  Link,
  LinkProps,
} from '@chakra-ui/react';
import {useSubscription} from 'observable-hooks';
import {motion} from 'framer-motion';
import {buttonLinkStyles, sharedBtnStyles} from './ButtonStyles';

interface MotionProps {
  /** The Framer Motion variants and inital variant options */
  motionOptions?: MotionOptions;
  /** The current variant animation state of the Framer Motion component */
  animate?: string;
}

interface SharedProps extends ModuleProperties, SchemaType {
  ariaLabel?: string;
  /**
   * @default false
   */
  disabled?: boolean;
  /**
   * @default false
   */
  isLoading?: boolean;
  motionProps?: MotionProps;
}

interface HtmlLinkProps {
  /**
   * @default false
   */
  isExternal?: boolean;
  href: string;
}

interface HtmlButtonProps {
  /**
   * @default 'button'
   */
  htmlType?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
}

export type IButtonProps = (HtmlButtonProps | HtmlLinkProps) &
  SharedProps & {
    cssProp?: SerializedStyles;
    ignorePointerEvents?: boolean;
  };

export type ButtonComponentDefinition = ContentModule<
  'Button',
  IButtonProps & SchemaType
>;

const spinAround = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg)
  }
`;

// framer motion components for Chakra UI
const MotionBtn = motion<ButtonProps>(ButtonElm);
const MotionLink = motion<LinkProps>(Link);

const Button: FC<IButtonProps & WithHtmlId> = (props) => {
  const spinAnimation = `${spinAround} 0.5s infinite linear`;
  const {
    ariaLabel,
    children,
    disabled = false,
    isLoading = false,
    motionProps,
    id,
    ignorePointerEvents,
  } = props;

  return 'href' in props ? (
    <MotionLink
      id={id}
      href={props.href}
      isExternal={props.isExternal}
      aria-label={
        ariaLabel || (typeof children === 'string' ? children : 'Link')
      }
      {...sharedBtnStyles({...props, spinAnimation, ignorePointerEvents})}
      {...buttonLinkStyles}
      {...motionProps}
    >
      {children}
    </MotionLink>
  ) : (
    <MotionBtn
      id={id}
      onClick={props.onClick}
      type={props.htmlType ?? 'button'}
      isLoading={isLoading}
      disabled={isLoading || disabled}
      variant="solid"
      role="button"
      aria-label={
        ariaLabel || (typeof children === 'string' ? children : 'Button')
      }
      {...sharedBtnStyles({...props, spinAnimation, ignorePointerEvents})}
      {...motionProps}
    >
      {children}
    </MotionBtn>
  );
};

export const ButtonComponent: VFC<ButtonComponentDefinition> = (definition) => {
  const {props, id, config} = definition;
  const {animationStates} = props;
  const [activeVariant, setActiveVariant] = useState<string | undefined>(
    undefined
  );
  const motionOptions = useMotionOptions(animationStates);

  const styles = css`
    ${definition.style}
    ${definition.props.styleAttr}
  `;

  const {observable, broadcast} = useShowInstructions(instructions, definition);

  const onClick = (): void => {
    if ('onClick' in props && typeof props.onClick === 'function') {
      props.onClick();
    }

    broadcast({type: `${namespace}:on-click`, meta: {}});
  };

  useSubscription(observable, (inst) => {
    if (inst.type === `${namespace}:click`) {
      onClick();
    } else if (inst.type === 'Button:animationState') {
      applyTween(inst, animationStates, setActiveVariant);
    }
  });

  return (
    <Button
      {...props}
      onClick={onClick}
      ignorePointerEvents={config.scope === 'admin'}
      cssProp={styles}
      id={id}
      motionProps={{
        animate: activeVariant,
        ...motionOptions,
      }}
    />
  );
};
