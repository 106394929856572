import {
  styleAttr,
  styleAttrUi,
  animationStates,
  animationUi,
  ComponentAdmin,
  SubscribesTo,
  animationDescription,
  scrollToDescription,
  ModuleCategory,
  layoutSchema,
  spacingSchema,
  backgroundSchema,
  sizingSchema,
  StringEnum,
} from '@backstage-components/base';
import {Static, Type} from '@sinclair/typebox';

export const reactName = 'Stacked';
export const name = reactName;
export const description = reactName;
const category: ModuleCategory = 'layout';

export const defaultFieldData = {
  layout: {
    orientation: 'vertical',
  },
  background: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
};

const presets = [
  'start',
  'center',
  'end',
  'space-between',
  'space-around',
  'space-evenly',
] as const;
export type Preset = typeof presets[number];

export const schema = Type.Object(
  {
    autoLayout: Type.Optional(
      Type.Boolean({
        title: 'Enable Auto Layout',
        default: false,
      })
    ),
    layout: Type.Optional(
      Type.Intersect(
        [
          layoutSchema,
          Type.Object({
            preset: Type.Optional(
              StringEnum(presets, {
                title: 'Preset',
              })
            ),
          }),
        ],
        {
          title: 'Layout',
          description:
            'If a "preset" is selected, will return a flex-layout based on selection. Falls back on orientation',
        }
      )
    ),
    spacing: Type.Optional(spacingSchema),
    background: Type.Optional(backgroundSchema),
    sizing: Type.Optional(
      Type.Pick(sizingSchema, ['maxHeight', 'minHeight', 'height'])
    ),
    animationStates,
    styleAttr,
  },
  {
    description:
      'If autoLayout is checked, a flex property shorthand will be passed to children. False by default',
  }
);

export type SchemaType = Static<typeof schema>;

export const uiSchema = {
  'ui:groups': {
    'ui:template': 'tabs',
    initialSection: 'Styling',
    sections: [
      // ['Properties', []],
      [
        'Styling',
        [
          {
            'ui:template': 'accordion',
            sections: [
              ['Layout', ['autoLayout', 'layout']],
              ['Spacing', ['spacing']],
              ['Sizing', ['sizing']],
              ['Background', ['background']],
              ['Typography', ['typography']],
            ],
          },
          'opacity',
          'styleAttr',
        ],
      ],
      ['Animation', ['animationStates']],
    ],
  },
  ...styleAttrUi,
  ...animationUi,
  layout: {
    orientation: {
      'ui:widget': 'LayoutWidget',
      'ui:options': {
        inline: true,
      },
    },
    preset: {
      'ui:widget': 'LayoutWidget',
      'ui:options': {
        inline: true,
      },
    },
    align: {
      'ui:widget': 'LayoutWidget',
      'ui:options': {
        inline: true,
      },
    },
    'ui:order': ['orientation', 'align', 'preset', 'gap', '*'],
  },
  spacing: {
    margin: {
      'ui:widget': 'SpacingWidget',
      'ui:options': {
        type: 'margin',
      },
    },
    padding: {
      'ui:widget': 'SpacingWidget',
      'ui:options': {
        type: 'padding',
      },
    },
  },
  background: {
    backgroundColor: {
      'ui:widget': 'color',
    },
  },
};

export const instructions = Type.Union([
  SubscribesTo({
    topic: `${reactName}:animationState`,
    description: animationDescription,
    meta: {
      stateName: Type.String(),
    },
  }),
  SubscribesTo({
    topic: `${reactName}:scrollTo`,
    description: scrollToDescription,
    meta: {
      scrollX: Type.Optional(Type.String()),
      scrollY: Type.Optional(Type.String()),
      anchorElId: Type.Optional(Type.String()),
      elementId: Type.Optional(Type.String()),
    },
  }),
]);

export const ComponentDefinition: ComponentAdmin = {
  id: '57accedd-28cd-4c27-9d27-2b185af3a88c',
  reactName,
  name: 'Stacked Layout',
  slug: reactName,
  description: 'Stacked layout',
  version: 1,
  defaultFieldData,
  slotConfiguration: {
    items: {maxModules: 100, displayWidth: 12, title: 'Modules'},
  },
  schema,
  uiSchema,
  instructions,
  category,
};
