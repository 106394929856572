import {
  styleAttr,
  styleAttrUi,
  ComponentAdmin,
  OptionalString,
  StringEnum,
  ModuleCategory,
} from '@backstage-components/base';
import {Static, Type} from '@sinclair/typebox';
import {
  numberValidationOptionGroupSchema,
  textValidationOptionGroupSchema,
  InputTypes,
} from './helpers/react-hook-form/typebox-helpers';

export const reactName = 'TextInput';
export const name = 'Input';
export const description = reactName;
const category: ModuleCategory = 'form';

export const schema = Type.Object(
  {
    name: Type.String({title: 'Input Id'}),
    label: OptionalString({title: 'Input Label'}),
    placeholder: OptionalString({title: 'Input Placeholder'}),
    inputType: StringEnum(InputTypes, {title: 'Input Type'}),
    sizing: Type.Optional(
      Type.Object({
        size: Type.Optional(
          StringEnum(['sm', 'md', 'lg'] as const, {
            title: 'Size',
            default: 'md',
          })
        ),
      })
    ),
    styleAttr,
  },
  {
    dependencies: {
      inputType: {
        oneOf: [
          {
            properties: {
              inputType: {
                enum: ['text'],
              },
              validationOptions: textValidationOptionGroupSchema,
            },
          },
          {
            properties: {
              inputType: {
                enum: ['number'],
              },
              validationOptions: numberValidationOptionGroupSchema,
            },
          },
        ],
      },
    },
  }
);

export type SchemaType = Static<typeof schema>;

export const uiSchema = {
  'ui:order': ['inputType', 'validationOptions', '*', 'styleAttr'],
  ...styleAttrUi,
};

export const defaultFieldData: SchemaType = {
  inputType: 'text',
  name: 'default-name',
  label: 'text-input-label',
};

export const ComponentDefinition: ComponentAdmin = {
  id: '440d33c5-3a8e-47ab-a03c-12f4c5edecb7',
  reactName,
  name,
  slug: reactName,
  description,
  version: 1,
  defaultFieldData,
  slotConfiguration: {},
  schema,
  uiSchema,
  category,
};
