import {ApolloClient} from '@apollo/client';
import type {BroadcastFn} from '@backstage-components/base';
import {assign, createMachine} from 'xstate';
import {
  AccessCodeInstruction,
  AccessCodeInstructionSchema,
} from './AccessCodeDefinition';
import {
  ResendAccessCode,
  ResendAccessCodeMutation,
  ResendAccessCodeVariables,
} from './gql';

interface ViewContext {
  broadcast: BroadcastFn<typeof AccessCodeInstructionSchema>;
  client: ApolloClient<object>;
  error?: string;
}

type Typestate =
  | {value: 'login'; context: ViewContext}
  | {value: 'loginPending'; context: ViewContext}
  | {value: 'resend'; context: ViewContext}
  | {value: 'resendPending'; context: ViewContext}
  | {value: 'resendSuccess'; context: ViewContext}
  | {value: 'success'; context: ViewContext};

export const AccessCodeViewMachine = createMachine<
  ViewContext,
  AccessCodeInstruction,
  Typestate
>(
  {
    id: 'AccessCodeResend',
    initial: 'login',
    states: {
      login: {
        on: {
          'AccessCode:resend-view': {target: 'resend'},
          'AccessCode:verify': {target: 'loginPending'},
        },
      },
      loginPending: {
        on: {
          'AccessCode:on-failure': {
            target: 'login',
            actions: [assign({error: (context, event) => event.meta.reason})],
          },
          'AccessCode:on-success': {target: 'success'},
        },
      },
      resend: {
        on: {
          'AccessCode:login-view': {target: 'login'},
          'AccessCode:resend': {
            target: 'resendPending',
            actions: ['resendAccessCode'],
          },
        },
      },
      resendPending: {
        on: {
          'AccessCode:on-resend-failure': {
            target: 'resend',
            actions: [assign({error: (context, event) => event.meta.reason})],
          },
          'AccessCode:on-resend-success': {target: 'resendSuccess'},
        },
      },
      resendSuccess: {
        on: {
          'AccessCode:login-view': {target: 'login'},
        },
      },
      success: {
        type: 'final',
      },
    },
  },
  {
    actions: {
      resendAccessCode: async (context, event) => {
        if (event.type === 'AccessCode:resend') {
          try {
            const result = await context.client.mutate<
              ResendAccessCode,
              ResendAccessCodeVariables
            >({
              mutation: ResendAccessCodeMutation,
              variables: {
                data: {
                  email: event.meta.email,
                  showId: event.meta.showId,
                },
              },
            });
            const response = result.data?.triggerResendAccessCode;
            if (response && 'id' in response) {
              return context.broadcast({
                type: 'AccessCode:on-resend-success',
                meta: {},
              });
            } else {
              return context.broadcast({
                type: 'AccessCode:on-resend-failure',
                meta: {
                  reason: response ? response.message : 'No response from API',
                },
              });
            }
          } catch (err) {
            return context.broadcast({
              type: 'AccessCode:on-resend-failure',
              meta: {
                reason:
                  err instanceof Error ? err.message : JSON.stringify(err),
              },
            });
          }
        }
      },
    },
  }
);
