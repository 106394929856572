import {VFC, useEffect, useCallback, useRef, FormEventHandler} from 'react';
import {useApolloClient} from '@apollo/client';
import {css} from '@emotion/react';
import {
  BroadcastFn,
  ContentModule,
  extractModuleIdentifiers,
  ModuleProperties,
  useParsedText,
  useShowId,
  useShowInstructions,
} from '@backstage-components/base';
import {ButtonComponent, ButtonProps} from '@backstage-components/button';
import {
  TextInputProps,
  TextInputComponent,
} from '@backstage-components/text-input';
import {useMachine} from '@xstate/react';
import {useSubscription} from 'observable-hooks';
import {
  useForm,
  SubmitHandler,
  FieldValues,
  UseFormReturn,
} from 'react-hook-form';
import {
  defaultFieldData,
  SchemaType,
  AccessCodeInstructionSchema,
} from './AccessCodeDefinition';
import * as Styled from './AccessCode.styled';
import {
  AccessCodeMachine,
  type AccessCodeSuccessEvent,
} from './AccessCodeMachine';
import {AccessCodeViewMachine} from './AccessCodeViewMachine';

export const defaultAccessCodeProps: Required<
  Pick<
    IAccessCodeProps,
    | 'title'
    | 'subtitle'
    | 'termsLinkProps'
    | 'codeTextInputProps'
    | 'submitButtonProps'
    | 'resendButtonProps'
  >
> = {
  title: 'WELCOME',
  subtitle: 'Enter your one-time access code we sent to your email.',
  termsLinkProps: {
    content: 'I agree to the <a href="#" target="_blank">Terms and Policy</a>',
  },
  submitButtonProps: {
    border: {
      borderRadius: '100px',
    },
    htmlType: 'submit',
    children: 'SUBMIT',
  },
  codeTextInputProps: {
    name: 'accessCode',
    id: 'access-code',
    placeholder: 'Enter Passcode',
    inputType: 'text',
    validationOptions: {
      required: 'code cannot be empty',
      minLength: {
        value: 1,
        message: `your code should be at least a character`,
      },
    },
  },
  resendButtonProps: {
    background: {
      buttonColor: 'transparent',
    },
    children: 'Resend My Code',
    typography: {
      fontSize: '10px',
    },
  },
};

export interface IAccessCodeProps extends ModuleProperties {
  title: string;
  subtitle?: string;
  accessCodeLength?: number;
  showResendLink?: boolean;
  submitButtonProps: ButtonProps;
  codeTextInputProps: TextInputProps;
  termsLinkProps?: {content: string};
  resendButtonProps?: ButtonProps;
  routeOnCodeAuthSuccess?: string;
  magicLinkKey?: string;
}

export type AccessCodeComponentDefinition = ContentModule<
  'AccessCode',
  IAccessCodeProps & SchemaType
>;

export const AccessCodeComponent: VFC<AccessCodeComponentDefinition> = (
  definition
) => {
  const {config} = definition;
  const {
    showTermsCheckbox = true,
    accessCodeErrorMessage = 'Incorrect access code provided',
    magicLinkSupport,
    magicLinkKey = 'ac',
  } = definition.props;

  const client = useApolloClient();
  const showId = useShowId();
  const {observable, broadcast} = useShowInstructions(
    AccessCodeInstructionSchema,
    definition
  );
  const [state, dispatch] = useMachine(AccessCodeMachine, {
    context: {broadcast, showId},
  });
  const [viewState, viewDispatch] = useMachine(AccessCodeViewMachine, {
    context: {broadcast, client},
  });
  const form = useForm({
    /**
     * @param mode defines when validation is triggered.
     * AccessCode is set at 'onSubmit'
     */
    mode: 'onSubmit',
  });
  const {handleSubmit, setError, setValue, getValues} = form;

  useEffect(() => {
    if (state.matches('failure')) {
      setError('accessCode', {
        message: state.context.reason || accessCodeErrorMessage,
      });
    }
  }, [accessCodeErrorMessage, setError, state]);
  useSubscription(observable, {
    next: (instruction) => dispatch(instruction),
  });
  useSubscription(observable, {
    next: (instruction) => viewDispatch(instruction),
  });

  useEffect(() => {
    const onAccessCodeSuccess = (e: AccessCodeSuccessEvent): void => {
      const {detail} = e;
      broadcast({
        type: 'AccessCode:on-success',
        meta: {
          showId: detail.showId,
          attendeeId: detail.attendee.id,
          attendeeEmail: detail.attendee.email,
          attendeeName: detail.attendee.name,
        },
      });
    };
    document.body.addEventListener('AccessCode:success', onAccessCodeSuccess);
    return () => {
      document.body.removeEventListener(
        'AccessCode:success',
        onAccessCodeSuccess
      );
    };
  }, [broadcast]);

  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (data) => {
      // Send the instruction to the state machine, which will trigger the
      // broadcast
      if (typeof data.accessCode !== 'string') {
        return;
      }
      dispatch({
        type: 'AccessCode:verify',
        meta: {accessCode: data.accessCode, showId},
      });
    },
    [dispatch, showId]
  );

  const hasAutoSubmitExecuted = useRef(false);

  useEffect(() => {
    if (
      !window?.location ||
      config.scope !== 'attendee' ||
      magicLinkSupport === 'none' ||
      hasAutoSubmitExecuted.current === true
    ) {
      return;
    }

    // ensure effect only runs once
    hasAutoSubmitExecuted.current = true;

    if (
      magicLinkSupport === 'auto-fill' ||
      magicLinkSupport === 'auto-submit'
    ) {
      const inputKey = defaultAccessCodeProps.codeTextInputProps.name;
      const query = window.location.search;
      const params = new URLSearchParams(query);
      const ac = params.get(magicLinkKey) ?? '';

      if (getValues(inputKey) === '') {
        setValue(inputKey, ac);
      }
      if (
        ac.length > 0 &&
        !showTermsCheckbox &&
        magicLinkSupport === 'auto-submit'
      ) {
        handleSubmit(onSubmit)();
      }
    }
  }, [
    config.scope,
    getValues,
    handleSubmit,
    magicLinkKey,
    magicLinkSupport,
    onSubmit,
    setValue,
    showTermsCheckbox,
  ]);

  if (viewState.matches('login') || viewState.matches('loginPending')) {
    return (
      <VerifyAccessCode
        {...definition}
        broadcast={broadcast}
        error={viewState.context.error}
        form={form}
        isLoading={viewState.value === 'loginPending'}
        showId={showId}
      />
    );
  } else if (
    viewState.matches('resend') ||
    viewState.matches('resendPending') ||
    viewState.matches('resendSuccess')
  ) {
    return (
      <ResendAccessCode
        {...definition}
        broadcast={broadcast}
        error={viewState.context.error}
        isLoading={viewState.value === 'resendPending'}
        isSuccess={viewState.value === 'resendSuccess'}
        showId={showId}
      />
    );
  } else {
    // Access Code verification success
    return null;
  }
};

interface VerifyAccessCodeProps extends AccessCodeComponentDefinition {
  broadcast: BroadcastFn<typeof AccessCodeInstructionSchema>;
  error?: string;
  form: Pick<
    UseFormReturn<FieldValues>,
    'formState' | 'handleSubmit' | 'register'
  >;
  isLoading?: boolean;
  showId: string;
}

export const VerifyAccessCode: VFC<VerifyAccessCodeProps> = (props) => {
  const {
    broadcast,
    error,
    form,
    isLoading = false,
    showId,
    ...definition
  } = props;
  const moduleIdentifiers = extractModuleIdentifiers(definition);

  const {id, mid, config} = definition;
  const checkboxId = `terms-checkbox-${id}`;
  const {
    title,
    subtitle,
    codeTextInputProps,
    termsLinkProps,
    resendButtonProps,
    submitButtonProps,
    accessCodeLength = 10,
    showResendLink = false,
    showTermsCheckbox = true,
    validationMessages,
  } = definition.props;

  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (data) => {
      // Send the instruction to the state machine, which will trigger the
      // broadcast
      if (typeof data.accessCode !== 'string') {
        return;
      }
      broadcast({
        type: 'AccessCode:verify',
        meta: {accessCode: data.accessCode, showId},
      });
    },
    [broadcast, showId]
  );

  const validationOptions = {
    required:
      validationMessages?.accessCodeRequired ??
      defaultFieldData.validationMessages.accessCodeRequired,
    minLength: {
      value: accessCodeLength,
      message: (
        validationMessages?.accessCodeMinLength ??
        defaultFieldData.validationMessages.accessCodeMinLength
      ).replace(/\{accessCodeLength\}/g, `${accessCodeLength}`),
    },
    maxLength: {
      value: accessCodeLength,
      message: (
        validationMessages?.accessCodeMaxLength ??
        defaultFieldData.validationMessages.accessCodeMaxLength
      ).replace(/\{accessCodeLength\}/g, `${accessCodeLength}`),
    },
  };

  const styles = css`
    ${definition.style}
    ${definition.props.styleAttr}
  `;
  const {register, formState, handleSubmit} = form;
  const errors = formState.errors;

  const termsLinkContent = useParsedText(
    termsLinkProps?.content ?? 'No content for terms link provided'
  );

  return (
    <Styled.Container id={id} css={styles} className="access-code-wrapper">
      <Styled.Title className="access-code-title">
        {title || defaultAccessCodeProps.title}
      </Styled.Title>
      <Styled.Subtitle className="access-code-subtitle">
        {subtitle || defaultAccessCodeProps.subtitle}
      </Styled.Subtitle>
      <Styled.Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        {termsLinkProps && showTermsCheckbox && (
          <Styled.CheckboxInputGroup className="access-code-checkbox-input-group">
            <Styled.Checkbox
              id={checkboxId}
              role="checkbox"
              className="access-code-checkbox-input"
              type="checkbox"
              {...register('terms', {
                required:
                  validationMessages?.checkboxRequired ||
                  defaultFieldData.validationMessages.checkboxRequired,
              })}
            />
            <Styled.CheckboxLabel
              htmlFor={checkboxId}
              className="access-code-checkbox-label"
            >
              {termsLinkContent}
            </Styled.CheckboxLabel>
          </Styled.CheckboxInputGroup>
        )}
        <Styled.CodeInputGroup className="access-code-text-input-group">
          <TextInputComponent
            config={config}
            {...moduleIdentifiers}
            mid={`${mid}-access-code-text-input`}
            component={'TextInput'}
            props={{
              disabled: isLoading,
              parentRegister: register,
              ...codeTextInputProps,
              name: defaultAccessCodeProps.codeTextInputProps.name,
              inputType: defaultAccessCodeProps.codeTextInputProps.inputType,
              validationOptions: accessCodeLength
                ? validationOptions
                : defaultAccessCodeProps.codeTextInputProps.validationOptions,
              styleAttr: `${codeTextInputProps?.styleAttr}
              ${Styled.codeTextInputStyle}`,
            }}
          />
          {!('href' in defaultAccessCodeProps.submitButtonProps) && (
            <ButtonComponent
              config={config}
              {...moduleIdentifiers}
              mid={`${mid}-access-code-submit-button`}
              component={'Button'}
              props={{
                ...submitButtonProps,
                disabled: isLoading,
                isLoading,
                children:
                  submitButtonProps?.children ||
                  defaultAccessCodeProps?.submitButtonProps?.children,
                border: defaultAccessCodeProps.submitButtonProps?.border,
                htmlType: defaultAccessCodeProps.submitButtonProps.htmlType,
              }}
            />
          )}
        </Styled.CodeInputGroup>

        <ErrorMessage
          className="access-code-text-input-error-message"
          error={
            error ??
            errors?.['terms']?.message ??
            errors?.['accessCode']?.message
          }
        />
      </Styled.Form>

      {resendButtonProps && showResendLink && (
        <div className="resend-button-component">
          <ButtonComponent
            config={config}
            {...moduleIdentifiers}
            mid={`${mid}-access-code-resend-button`}
            component={'Button'}
            props={{
              onClick: () =>
                broadcast({type: 'AccessCode:resend-view', meta: {}}),
              ...resendButtonProps,
              background: defaultAccessCodeProps.resendButtonProps?.background,
              children:
                resendButtonProps.children ||
                defaultAccessCodeProps.resendButtonProps?.children,
              typography: defaultAccessCodeProps.resendButtonProps?.typography,
            }}
          />
        </div>
      )}
    </Styled.Container>
  );
};

interface ResendAccessCodeProps extends AccessCodeComponentDefinition {
  broadcast: BroadcastFn<typeof AccessCodeInstructionSchema>;
  error?: string;
  isLoading?: boolean;
  isSuccess?: boolean;
  showId: string;
}

const ResendAccessCode: VFC<ResendAccessCodeProps> = (props) => {
  const {
    broadcast,
    error,
    isLoading = false,
    isSuccess = false,
    showId,
    ...definition
  } = props;
  const {id, mid, config} = definition;
  const moduleIdentifiers = extractModuleIdentifiers(definition);
  const styles = css`
    ${definition.style}
    ${definition.props.styleAttr}
  `;
  const formRef = useRef<HTMLFormElement | null>(null);
  const {
    codeTextInputProps,
    resendAccessCodeFormContent,
    resendButtonProps,
    submitButtonProps,
    validationMessages,
  } = definition.props;
  const handleSubmit: FormEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      if (!formRef.current) {
        return;
      }
      const data = new FormData(formRef.current);
      const email = data.get('email');
      if (email === null || typeof email !== 'string') {
        broadcast({
          type: 'AccessCode:on-resend-failure',
          meta: {
            reason:
              validationMessages?.emailRequired ?? 'Email must be provided',
          },
        });
      } else {
        broadcast({type: 'AccessCode:resend', meta: {email, showId}});
      }
    },
    [broadcast, showId, validationMessages]
  );
  const backLabel =
    resendAccessCodeFormContent?.backButtonText ??
    defaultFieldData.resendAccessCodeFormContent.backButtonText;
  const title =
    resendAccessCodeFormContent?.title ??
    defaultFieldData.resendAccessCodeFormContent.title;
  const subtitle =
    resendAccessCodeFormContent?.subtitle ??
    defaultFieldData.resendAccessCodeFormContent.subtitle;
  const submitLabel =
    resendAccessCodeFormContent?.submitButtonLabel ??
    defaultFieldData.resendAccessCodeFormContent.submitButtonLabel;
  const successMessage =
    resendAccessCodeFormContent?.successMessage ??
    defaultFieldData.resendAccessCodeFormContent.successMessage;

  return (
    <Styled.Container id={id} css={styles} className="access-code-wrapper">
      <Styled.Title className="access-code-title">{title}</Styled.Title>
      <Styled.Subtitle className="access-code-subtitle">
        {subtitle}
      </Styled.Subtitle>
      <Styled.Form ref={formRef} autoComplete="off" onSubmit={handleSubmit}>
        <Styled.CodeInputGroup className="access-code-text-input-group">
          <TextInputComponent
            config={config}
            {...moduleIdentifiers}
            mid={`${mid}-access-code-text-input`}
            component="TextInput"
            props={{
              ...codeTextInputProps,
              disabled: isLoading || isSuccess,
              name: 'email',
              inputType: defaultAccessCodeProps.codeTextInputProps.inputType,
              styleAttr: `${codeTextInputProps?.styleAttr}
              ${Styled.codeTextInputStyle}`,
            }}
          />
          {!('href' in defaultAccessCodeProps.submitButtonProps) && (
            <ButtonComponent
              config={config}
              {...moduleIdentifiers}
              mid={`${mid}-access-code-submit-button`}
              component={'Button'}
              props={{
                ...submitButtonProps,
                disabled: isLoading || isSuccess,
                isLoading,
                children: submitLabel,
                border: defaultAccessCodeProps.submitButtonProps?.border,
                htmlType: defaultAccessCodeProps.submitButtonProps.htmlType,
              }}
            />
          )}
        </Styled.CodeInputGroup>
        <SuccessMessage
          className="access-code-text-input-success-message"
          message={isSuccess ? successMessage : undefined}
        />
        <ErrorMessage
          className="access-code-text-input-error-message"
          error={error}
        />
      </Styled.Form>
      <div className="resend-button-component">
        <ButtonComponent
          config={config}
          {...moduleIdentifiers}
          mid={`${mid}-access-code-resend-button`}
          component={'Button'}
          props={{
            onClick: () => broadcast({type: 'AccessCode:login-view', meta: {}}),
            ...resendButtonProps,
            background: defaultAccessCodeProps.resendButtonProps?.background,
            children: backLabel,
            typography: defaultAccessCodeProps.resendButtonProps?.typography,
          }}
        />
      </div>
    </Styled.Container>
  );
};

/**
 * Displays a stylized error message if an `error` string is provided.
 */
const ErrorMessage: VFC<{className: string; error?: string}> = (props) => {
  const {className, error} = props;
  if (error) {
    return (
      <Styled.ErrorMessage data-testid={className} className={className}>
        {error}
      </Styled.ErrorMessage>
    );
  } else {
    return null;
  }
};

/**
 * Displays a stylized error message if a `message` string is provided.
 */
const SuccessMessage: VFC<{className: string; message?: string}> = (props) => {
  const {className, message} = props;
  if (message) {
    return (
      <Styled.SuccessMessage data-testid={className} className={className}>
        {message}
      </Styled.SuccessMessage>
    );
  } else {
    return null;
  }
};
