import {JSONValue} from '@backstage-components/base';
import {LayoutProps} from '@chakra-ui/react';
import {SerializedStyles} from '@emotion/utils';
import {IButtonProps} from './ButtonComponent';

type sharedStyles = IButtonProps & {
  spinAnimation: string;
  ignorePointerEvents?: boolean;
};

type Styles = Record<string, JSONValue | undefined | SerializedStyles>;

export const sharedBtnStyles = ({
  background,
  typography,
  spacing,
  border,
  sizing,
  ignorePointerEvents,
  cssProp,
  spinAnimation,
}: sharedStyles): Styles => {
  return {
    css: cssProp,
    bg: background?.buttonColor,
    color: typography?.textColor,
    fontSize: typography?.fontSize,
    fontFamily: typography?.fontFamily || 'Helvetica',
    fontWeight: '500',
    lineHeight: '150%',
    letterSpacing: '0.03em',
    borderColor: border?.borderColor,
    borderWidth: border?.borderWidth,
    borderStyle: border?.borderStyle,
    borderRadius: border?.borderRadius,
    w: sizing?.width,
    padding: spacing?.padding,
    pointerEvents: ignorePointerEvents ? 'none' : 'initial',
    boxSizing: 'border-box',
    cursor: 'pointer',
    textDecoration: 'none',
    _active: {opacity: 1, color: '#fff'},
    _focusVisible: {outline: '#006aeb auto 1px'},
    _focus: {outline: 'none'},
    _hover: {bg: background?.buttonColor ?? '#000000'},
    _disabled: {opacity: 0.3, cursor: 'default', pointerEvents: 'none'},
    _loading: {
      color: 'transparent',
      position: 'relative',
      bg: background?.buttonColor ?? '#000000',
      opacity: '0.7',
      cursor: 'default',
      border: 'none',
      boxShadow: 'none',
      pointerEvents: 'none',
      textIndent: '-999em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      _after: {
        animation: spinAnimation,
        border: '2px solid #fff',
        borderRadius: '290486px',
        borderRightColor: 'transparent',
        borderTopColor: 'transparent',
        content: `""`,
        display: 'block',
        h: '1.6em',
        w: '1.6em',
        position: 'absolute',
      },
    },
  };
};

export const buttonLinkStyles: Styles = {
  fontWeight: '500',
  lineHeight: '150%',
  letterSpacing: '0.03em',
  boxSizing: 'border-box' as LayoutProps['boxSizing'],
  cursor: 'pointer',
  textDecoration: 'none',
};
