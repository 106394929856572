import {
  styleAttr,
  styleAttrUi,
  animationStates,
  animationUi,
  ComponentAdmin,
  PublishesTo,
  SubscribesTo,
  OptionalString,
  ModuleCategory,
} from '@backstage-components/base';
import {Static, Type} from '@sinclair/typebox';

export const reactName = 'RtcRoomContainer';
export const name = 'Real-time Room Container';
export const description = reactName;
const category: ModuleCategory = 'rtc';

export const schema = Type.Object({
  roomName: OptionalString({title: 'Display Name'}),
  roomChannelName: Type.Optional(
    Type.String({
      title: 'Room Channel Name',
      default: true,
      description: 'Name of the room for video participants',
    })
  ),
  requestVideoDevices: Type.Optional(
    Type.Boolean({
      title: 'Video devices?',
      default: true,
      description: 'Request access to video devices.',
    })
  ),
  requestAudioDevices: Type.Optional(
    Type.Boolean({
      title: 'Audio devices?',
      default: true,
      description: 'Request access to audio devices.',
    })
  ),
  animationStates,
  styleAttr,
});

export type SchemaType = Static<typeof schema>;

export const uiSchema = {
  ...styleAttrUi,
  ...animationUi,
};

export const defaultFieldData: SchemaType = {
  styleAttr: '',
  animationStates: [],
  roomName: '',
  roomChannelName: '',
  requestAudioDevices: true,
  requestVideoDevices: true,
};

export const Speaker = Type.Object({
  sid: Type.String(),
  audioLevel: Type.Number(),
  identity: Type.String(),
  isSpeaking: Type.Boolean(),
  name: Type.Optional(Type.String()),
  metadata: Type.Optional(Type.String()),
  isLocal: Type.Boolean(),
});

export type Speaker = Static<typeof Speaker>;

export const RemoteParticipant = Type.Object({
  sid: Type.String(),
  audioLevel: Type.Optional(Type.Number()),
  identity: Type.Optional(Type.String()),
  isSpeaking: Type.Optional(Type.Boolean()),
  name: Type.Optional(Type.String()),
  metadata: Type.Optional(Type.String()),
});

const RemotePartcipantData = Type.Object({
  remoteParticipant: Type.Optional(RemoteParticipant),
});

export type RemoteParticipantData = Static<typeof RemotePartcipantData>;

const ReceivedData = Type.Object({
  type: Type.Optional(Type.String()),
  remoteParticipant: Type.Optional(RemoteParticipant),
});

export type ReceivedData = Static<typeof ReceivedData>;

export const TrackInfo = Type.Object({
  trackSid: Type.String(),
  participantSid: Type.String(),
  trackSource: Type.String(),
});

export const instructions = Type.Union([
  PublishesTo({
    topic: `${reactName}:on-active-speaker-changed`,
    meta: {
      participants: Type.Array(Speaker),
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-speaker-start`,
    meta: {
      ...Speaker.properties,
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-speaker-stop`,
    meta: {
      ...Speaker.properties,
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-room-connection`,
    meta: {
      remoteParticipants: Type.Array(RemoteParticipant),
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-audio-playback-change`,
    description: 'Audio playback status has changed.',
  }),
  PublishesTo({
    topic: `${reactName}:on-connection-quality-changed`,
    meta: {
      quality: Type.String(),
      participantSid: Type.String(),
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-local-track-published`,
  }),
  PublishesTo({
    topic: `${reactName}:on-local-track-unpublished`,
  }),
  PublishesTo({
    topic: `${reactName}:on-media-devices-error`,
  }),
  PublishesTo({
    topic: `${reactName}:on-media-devices-changed`,
  }),
  PublishesTo({
    topic: `${reactName}:on-state-changed`,
  }),
  PublishesTo({
    topic: `${reactName}:on-track-muted`,
    meta: {
      ...TrackInfo.properties,
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-track-unmuted`,
    meta: {
      ...TrackInfo.properties,
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-track-published`,
    meta: {
      ...TrackInfo.properties,
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-track-unpublished`,
    meta: {
      ...TrackInfo.properties,
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-track-subscribed`,
    meta: {
      ...TrackInfo.properties,
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-track-unsubscribed`,
    meta: {
      ...TrackInfo.properties,
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-participant-connected`,
    meta: {
      remoteParticipant: RemoteParticipant,
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-participant-disconnected`,
    meta: {
      remoteParticipant: RemoteParticipant,
    },
  }),
  SubscribesTo({
    topic: `${reactName}:request-device-permission`,
  }),
  PublishesTo({
    topic: `${reactName}:on-device-permission-granted`,
    meta: {
      videoDevices: Type.Array(
        Type.Object({
          label: Type.String(),
          kind: Type.String(),
          deviceId: Type.String(),
        })
      ),
      audioDevices: Type.Array(
        Type.Object({
          label: Type.String(),
          kind: Type.String(),
          deviceId: Type.String(),
        })
      ),
      activeVideoDeviceId: Type.Optional(Type.String()),
      activeAudioDeviceId: Type.Optional(Type.String()),
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-device-permission-denied`,
    meta: {
      deviceError: Type.String(),
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-audio-toggle-received`,
    meta: {
      data: Type.Object({
        remoteParticipant: Type.Optional(RemoteParticipant),
      }),
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-video-toggle-received`,
    meta: {
      data: Type.Object({
        remoteParticipant: Type.Optional(RemoteParticipant),
      }),
    },
  }),
]);

export const ComponentDefinition: ComponentAdmin = {
  id: '5c05a93a-846e-4521-81c7-5e6062068779',
  reactName,
  name,
  slug: reactName,
  description,
  version: 1,
  defaultFieldData,
  slotConfiguration: {
    items: {maxModules: 100, displayWidth: 12, title: 'Modules'},
  },
  schema,
  uiSchema,
  instructions,
  category,
};
